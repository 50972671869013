var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.type === 'label')?_c('div',{staticClass:"custom-label",class:[
    { 'custom-label__disabled': _vm.disabled || _vm.showLoader },
    { 'custom-label__full-width': _vm.isFullWidth } ],style:(_vm.specificWidth ? { width: _vm.specificWidth } : {}),on:{"click":_vm.handleClick}},[(_vm.link)?_c('SfLink',{class:[("" + _vm.customLabelClass)],attrs:{"link":_vm.link,"target":_vm.target}},[(_vm.showLoader)?_c('LoadingDots'):_vm._t("default")],2):_c('span',{class:("" + _vm.customLabelClass)},[(_vm.showLoader)?_c('LoadingDots'):_vm._t("default"),_vm._v(" "),(_vm.hasIcon && _vm.type !== 'icon')?_c(_vm.theme === 'grey' ? 'IconArrowBlack' : 'IconArrowWhite',{tag:"component",staticClass:"custom-label__icon"}):_vm._e()],2)],1):_c('SfButton',{class:[
    ("custom-button " + _vm.theme),
    ("custom-button " + _vm.size),
    { 'custom-button__transparent': _vm.isTransparent },
    { 'custom-button__transparent-on-hover': _vm.isTransparentOnHover },
    { 'custom-button__disabled': _vm.disabled || _vm.showLoader },
    { 'custom-button__full-width': _vm.isFullWidth },
    { 'custom-button__icon': _vm.hasIcon } ],style:(_vm.specificWidth ? { width: _vm.specificWidth } : {}),attrs:{"disabled":_vm.disabled || _vm.showLoader,"type":_vm.type,"link":_vm.link,"aria-label":_vm.ariaLabel,"target":_vm.target},on:{"click":_vm.handleClick}},[(_vm.showLoader)?_c('LoadingDots'):_vm._t("default"),_vm._v(" "),(_vm.hasIcon)?_c(_vm.icon,{tag:"component",staticClass:"custom-button__icon"}):_vm._e(),_vm._v(" "),(_vm.iconText !== '')?_c('p',{staticClass:"custom-button__icon__text"},[_vm._v("\n    "+_vm._s(_vm.iconText)+"\n  ")]):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }