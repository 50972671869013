<template>
  <div
    :class="[
      `custom-input__container custom-input-${inputType}`,
      disabled && 'custom-input__container__disabled',
      inputLabel && 'custom-input__container__with-label',
    ]"
  >
    <ValidationProvider
      :rules="validateRule"
      v-slot="{ errors }"
      class="custom-input__validator"
      :mode="interactionMode"
      :ref="inputId"
      :vid="inputId"
      :class="inputLabel && 'custom-input__validator__with-label'"
    >
      <label v-if="inputLabel" class="custom-input__label" :for="inputId">
        {{ $gt(inputLabel) }}
      </label>
      <input
        :id="inputId"
        :name="inputName"
        :type="inputType"
        v-model="value"
        class="custom-input"
        :class="[
          errors[0] && 'custom-input__has-errors',
          inputLabel && 'custom-input__with-label',
          disabled && 'custom-input__disabled',
          `${theme}`,
        ]"
        :style="overrideStyle || {}"
        @input="handleChange()"
        @blur="handleBlur()"
        @focus="handleFocus()"
        :placeholder="$gt(inputPlaceholder)"
      />
      <div class="custom-input__errors" v-if="errors[0]">
        {{ $t(errors[0]) }}
      </div>
    </ValidationProvider>
  </div>
</template>

<script>
import { defineComponent, ref, computed, watch } from '@nuxtjs/composition-api';
import { ValidationProvider, extend } from 'vee-validate';
import {
  email,
  required,
  alpha_num,
  confirmed,
  numeric,
} from 'vee-validate/dist/rules';
// import { PointRightBlack } from '~/components/General/Icons';
import libphonenumber from 'google-libphonenumber';
import { useI18n } from '~/helpers/hooks/usei18n';
import { useTranslation } from '~/composables';
import dataManipulation from '~/helpers/dataManipulation';

extend('numeric', {
  ...numeric,
  message: 'May only contain numeric characters',
});
extend('email', {
  ...email,
  message: 'The email format is not correct',
});
extend('alpha_num', {
  ...alpha_num,
  message: 'May only contain alpha-numeric characters',
});
extend('required', {
  ...required,
  message: 'Required field',
});
extend('confirmed', {
  ...confirmed,
  message: 'Field does not match',
});
extend('phone', {
  validate(value, arguments_) {
    if (
      !arguments_ ||
      !Array.isArray(arguments_) ||
      arguments_.length === 0 ||
      arguments_[0] === ''
    )
      return false;
    const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
    try {
      return phoneUtil.isValidNumberForRegion(
        phoneUtil.parse(value, arguments_[0]),
        arguments_[0]
      );
    } catch {
      return false;
    }
  },
});

export default defineComponent({
  name: 'CustomInput',
  components: {
    ValidationProvider,
  },
  props: {
    inputType: {
      type: String,
      default: 'text',
    },
    theme: {
      type: String,
      default: 'grey',
    },
    inputPlaceholder: {
      type: String,
      default: '',
    },
    inputName: {
      type: String,
      required: true,
    },
    inputValue: {
      type: [String, Object, null],
      default: null,
    },
    inputLabel: {
      type: String,
      required: false,
    },
    inputId: {
      type: String,
      required: true,
    },
    validateRule: {
      type: [Boolean, String],
      default: '',
    },
    interactionMode: {
      type: String,
      default: 'aggressive',
    },
    icon: {
      type: [Boolean, String],
      default: false,
    },
    overrideStyle: {
      type: Object,
      default: () => ({}),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    hasIcon: {
      type: Boolean,
      default: false,
    },
    hasButton: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const { $gt } = useTranslation('translations');
    const value = ref(props.inputValue);

    watch(
      () => props.inputValue,
      (newVal) => {
        value.value = newVal;
      }
    );

    const isEmail = computed(
      () =>
        (props.validateRule && props.validateRule.includes('email')) ||
        props.inputType === 'email'
    );

    const handleChange = () => {
      emit(
        'inputChange',
        value.value && isEmail.value ? value.value.toLowerCase() : value.value
      );
      emit(
        'input',
        value.value && isEmail.value ? value.value.toLowerCase() : value.value
      );
    };

    const handleBlur = () => {
      emit(
        'inputBlur',
        value.value && isEmail.value ? value.value.toLowerCase() : value.value
      );
      emit(
        'blur',
        value.value && isEmail.value ? value.value.toLowerCase() : value.value
      );
    };

    const handleFocus = () => {
      emit('inputFocus');
      emit('focus');
    };

    const trans = useI18n();
    const { isValidItalianFiscalCode } = dataManipulation();

    extend('min', {
      validate(v, arguments_) {
        return v.length >= arguments_.length;
      },
      params: ['length'],
      message: (_, arguments_) => {
        return trans.t(`Must be longer than than {0} characters`, [
          arguments_.length,
        ]);
      },
    });
    extend('max', {
      validate(v, arguments_) {
        return v.length < arguments_.length;
      },
      params: ['length'],
      message: (_, arguments_) => {
        return trans.t(`Must be shorter than {0} characters`, [
          arguments_.length,
        ]);
      },
    });
    extend('fiscal_code', {
      validate: (v) => v && isValidItalianFiscalCode(v),
      message: trans.t('Invalid fiscal code'),
    });

    return {
      value,
      handleBlur,
      handleChange,
      $gt,
      handleFocus,
    };
  },
});
</script>

<style lang="scss" scoped>
.custom-input {
  width: 100%;
  @include paragraph-regular;
  @include form-element-default-mobile-size;
  &__validator {
    &__with-label {
      @include flex-column;
      gap: 0.2rem;
    }
  }
  &__with-label {
    height: var(--form-element-mobile-height);
  }
  &__label {
    text-transform: uppercase;
  }
  &.grey {
    @include grey-input;
  }
  &.black {
    @include black-input;
  }
  &.white {
    @include white-input;
  }
  &__disabled {
    background-color: #e4e4e4 !important;
    pointer-events: none;
  }
  &__container {
    width: 100%;
    &__disabled {
      cursor: not-allowed;
    }
  }
  &__errors {
    @include paragraph-regular;
    color: var(--c-red-error);
    max-width: var(--form-element-mobile-width);
  }
  &__has-errors {
    outline: 1px solid var(--c-red-error);
  }
}
@include from-desktop-min {
  .custom-input {
    @include form-element-default-desktop-size;
    &__with-label {
      height: var(--form-element-desktop-height);
    }
    &__errors {
      max-width: var(--form-element-desktop-width);
    }
  }
}
.custom-input-full-width {
  .custom-input__input.sf-input {
    width: 100%;
  }
}
</style>
