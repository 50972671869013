


















import { defineComponent, ref } from '@nuxtjs/composition-api';
import { SfQuantitySelector } from '@storefront-ui/vue';
import { useTranslation } from '~/composables';

export default defineComponent({
  name: 'CustomQuantitySelector',
  components: {
    SfQuantitySelector,
  },
  props: {
    quantity: {
      type: Number,
    },
    minQuantity: {
      type: Number,
      default: null,
    },
    maxQuantity: {
      type: Number,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const selectedQuantity = ref<number>(props.quantity);
    const hasErrorMessage = ref<boolean>(false);
    const { $gt } = useTranslation('translations');

    const showQtyErrorMessage = (quantity: number) => {
      hasErrorMessage.value = !(quantity % props.minQuantity === 0);
      if (hasErrorMessage.value) {
        setTimeout(() => {
          hasErrorMessage.value = false;
        }, 3500);
      }
    };
    const operandClicked = (operand: string) => {
      if (operand === '+') {
        selectedQuantity.value += props.minQuantity;
      }
      if (operand === '-') {
        selectedQuantity.value -= props.minQuantity;
      }
      showQtyErrorMessage(selectedQuantity.value);
      selectedQuantity.value =
        selectedQuantity.value % props.minQuantity === 0
          ? selectedQuantity.value
          : props.minQuantity;
      emit('change', selectedQuantity.value);
    };

    const changedQty = (quantity) => {
      const quantityInt = Number.parseInt(quantity, 10);
      showQtyErrorMessage(quantityInt);
      selectedQuantity.value =
        quantityInt % props.minQuantity === 0 ? quantityInt : props.minQuantity;
      emit('change', selectedQuantity.value, hasErrorMessage.value);
    };

    return {
      operandClicked,
      changedQty,
      hasErrorMessage,
      selectedQuantity,
      $gt,
    };
  },
});
