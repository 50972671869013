<template>
  <div
    v-if="optionsArray"
    class="custom-select__container"
    :style="specificWidth ? { width: specificWidth } : {}"
  >
    <ValidationProvider
      :rules="isRequired ? 'required' : ''"
      v-slot="{ errors }"
      class="custom-select__validator"
      :class="isDisabled && 'custom-select__container__disabled'"
    >
      <span class="custom-select__title"> {{ $gt(selectTitle) }}</span>
      <SfSelect
        class="custom-select"
        :required="isRequired"
        :valid="isValid"
        :disabled="isDisabled"
        :placeholder="$gt(placeholder)"
        @input="handleChange"
        :value="selectValue || (defaultValue && defaultValue.value)"
        :class="blackOutline && 'custom-select__black-outline'"
        :style="specificWidth ? { width: specificWidth } : {}"
      >
        <SfSelectOption
          v-for="(option, index) in optionsArray"
          :value="option.value"
          :key="option.value + index"
          class="custom-select__option"
        >
          {{ option.label }}
        </SfSelectOption>
      </SfSelect>
      <span v-if="errors[0]" class="custom-select__error">
        {{ $t(errors[0]) }}
      </span>
    </ValidationProvider>
  </div>
</template>

<script>
import { defineComponent, ref, computed, watch } from '@nuxtjs/composition-api';
import { ArrowDown } from '~/components/General/Icons';
import { ValidationProvider, extend } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
import { SfSelect } from '@storefront-ui/vue';
import { useI18n } from '~/helpers/hooks/usei18n';
import { useTranslation } from '~/composables';

export default defineComponent({
  name: 'CustomSelect',
  components: {
    ValidationProvider,
    SfSelect,
    // eslint-disable-next-line vue/no-unused-components
    ArrowDown,
  },
  model: {
    prop: 'selectedValue',
  },
  props: {
    selectName: {
      type: String,
      required: true,
    },
    selectOptions: {
      type: Array,
      required: true,
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    selectIcon: {
      type: [Boolean, String],
      default: 'ArrowDown',
    },
    selectLabel: {
      type: String,
      default: 'Seleziona una opzione',
    },
    selectedValue: {
      type: [String, null],
      default: null,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    specificWidth: {
      type: [String, undefined],
      default: undefined,
    },
    isValid: {
      type: Boolean,
      default: true,
    },
    selectTitle: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: 'Select option',
    },
    blackOutline: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const selectValue = ref(props.selectedValue);
    const defaultValue = ref();
    const trans = useI18n();
    extend('required', {
      ...required,
      message: trans.t('Required field'),
    });
    const optionsArray = computed(() =>
      props.selectOptions && props.selectOptions.length > 0
        ? props.selectOptions
            .map((e) => {
              if (!Object.hasOwn(e, 'label')) {
                e.label = e.value;
              }
              if (Object.hasOwn(e, 'default')) {
                defaultValue.value = e;
                return null;
              }
              return e;
            })
            .filter(Boolean)
        : []
    );
    const { $gt } = useTranslation('translations');

    const handleChange = (value) => {
      selectValue.value = value;
      emit('selectChange', value);
      emit('input', value);
    };

    watch(optionsArray, (optionsArrayReference) => {
      if (optionsArrayReference.length === 1) {
        selectValue.value = optionsArrayReference[0].value.toString();
        handleChange(selectValue.value);
      }
    });

    watch(
      () => props.selectedValue,
      () => {
        selectValue.value = props.selectedValue;
        emit('input', props.selectedValue);
      }
    );

    return {
      optionsArray,
      selectValue,
      handleChange,
      defaultValue,
      $gt,
    };
  },
});
</script>

<style lang="scss">
.custom-select {
  @include border-radius-standard;
  padding: 0;

  &::after {
    display: none !important;
  }
  &__option {
    @include paragraph-regular;
  }
  &__title {
    @include black-cta-text;
    text-transform: uppercase;
  }

  &__container {
    .custom-select__validator {
      line-height: 14px;
      .custom-select__title {
        display: block;
        margin-bottom: 3px;
      }
    }
  }

  &__container,
  .sf-select__dropdown {
    @include form-element-default-mobile-size;
  }
  .sf-select {
    @include default-outline;

    &__dropdown {
      background: transparent;
      background-position: right;
      border-radius: 100px;
      margin: 0;
      appearance: none;
      padding: 0 1rem;
      background-image: url('/icons/ArrowDown.svg');
      background-repeat: no-repeat;
      background-position-x: 97%;
      background-position-y: 50%;
      width: 100%;
    }
    &__label {
      display: none;
    }
  }
  &__container {
    padding-bottom: 4rem;
  }
  &__black-outline {
    .sf-select__dropdown {
      outline: 1px solid var(--c-black) !important;
    }
  }
}

@include from-desktop-min {
  .custom-select {
    &__container,
    .sf-select__dropdown {
      width: 100%;
      height: var(--form-element-desktop-height);
    }
  }
}
</style>
